export const appHead = {"meta":[{"name":"viewport","content":"initial-scale=1"},{"charset":"utf-16"},{"name":"description","content":"Films Gratis c'est LE site qui va te permettre de trouver où visionner les films gratuits !"},{"name":"ahrefs-site-verification","content":"1237aa93bfdc3dadc502c7d1e73ddaae022768561c567689cd33a24ad37fe6bf"}],"link":[],"style":[],"script":[],"noscript":[],"charset":"utf-16","viewport":"initial-scale=1","title":"Films Gratis"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'