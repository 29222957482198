import { default as about2bi1FalvyPMeta } from "/root/films-gratis-fullstack/pages/about.vue?macro=true";
import { default as contactp0rMLyQ8hNMeta } from "/root/films-gratis-fullstack/pages/contact.vue?macro=true";
import { default as index0Qk6qoA1zpMeta } from "/root/films-gratis-fullstack/pages/index.vue?macro=true";
import { default as indexOLDWx8TyGtII5Meta } from "/root/films-gratis-fullstack/pages/indexOLD.vue?macro=true";
import { default as prismatsW0hdIMifMeta } from "/root/films-gratis-fullstack/pages/prisma.vue?macro=true";
import { default as trpcsNuzv5KgYBMeta } from "/root/films-gratis-fullstack/pages/trpc.vue?macro=true";
export default [
  {
    name: about2bi1FalvyPMeta?.name ?? "about___fr",
    path: about2bi1FalvyPMeta?.path ?? "/about",
    meta: about2bi1FalvyPMeta || {},
    alias: about2bi1FalvyPMeta?.alias || [],
    redirect: about2bi1FalvyPMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/about.vue").then(m => m.default || m)
  },
  {
    name: about2bi1FalvyPMeta?.name ?? "about___en",
    path: about2bi1FalvyPMeta?.path ?? "/en/about",
    meta: about2bi1FalvyPMeta || {},
    alias: about2bi1FalvyPMeta?.alias || [],
    redirect: about2bi1FalvyPMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactp0rMLyQ8hNMeta?.name ?? "contact___fr",
    path: contactp0rMLyQ8hNMeta?.path ?? "/contact",
    meta: contactp0rMLyQ8hNMeta || {},
    alias: contactp0rMLyQ8hNMeta?.alias || [],
    redirect: contactp0rMLyQ8hNMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactp0rMLyQ8hNMeta?.name ?? "contact___en",
    path: contactp0rMLyQ8hNMeta?.path ?? "/en/contact",
    meta: contactp0rMLyQ8hNMeta || {},
    alias: contactp0rMLyQ8hNMeta?.alias || [],
    redirect: contactp0rMLyQ8hNMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: index0Qk6qoA1zpMeta?.name ?? "index___fr",
    path: index0Qk6qoA1zpMeta?.path ?? "/",
    meta: index0Qk6qoA1zpMeta || {},
    alias: index0Qk6qoA1zpMeta?.alias || [],
    redirect: index0Qk6qoA1zpMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index0Qk6qoA1zpMeta?.name ?? "index___en",
    path: index0Qk6qoA1zpMeta?.path ?? "/en",
    meta: index0Qk6qoA1zpMeta || {},
    alias: index0Qk6qoA1zpMeta?.alias || [],
    redirect: index0Qk6qoA1zpMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOLDWx8TyGtII5Meta?.name ?? "indexOLD___fr",
    path: indexOLDWx8TyGtII5Meta?.path ?? "/indexOLD",
    meta: indexOLDWx8TyGtII5Meta || {},
    alias: indexOLDWx8TyGtII5Meta?.alias || [],
    redirect: indexOLDWx8TyGtII5Meta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/indexOLD.vue").then(m => m.default || m)
  },
  {
    name: indexOLDWx8TyGtII5Meta?.name ?? "indexOLD___en",
    path: indexOLDWx8TyGtII5Meta?.path ?? "/en/indexOLD",
    meta: indexOLDWx8TyGtII5Meta || {},
    alias: indexOLDWx8TyGtII5Meta?.alias || [],
    redirect: indexOLDWx8TyGtII5Meta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/indexOLD.vue").then(m => m.default || m)
  },
  {
    name: prismatsW0hdIMifMeta?.name ?? "prisma___fr",
    path: prismatsW0hdIMifMeta?.path ?? "/prisma",
    meta: prismatsW0hdIMifMeta || {},
    alias: prismatsW0hdIMifMeta?.alias || [],
    redirect: prismatsW0hdIMifMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/prisma.vue").then(m => m.default || m)
  },
  {
    name: prismatsW0hdIMifMeta?.name ?? "prisma___en",
    path: prismatsW0hdIMifMeta?.path ?? "/en/prisma",
    meta: prismatsW0hdIMifMeta || {},
    alias: prismatsW0hdIMifMeta?.alias || [],
    redirect: prismatsW0hdIMifMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/prisma.vue").then(m => m.default || m)
  },
  {
    name: trpcsNuzv5KgYBMeta?.name ?? "trpc___fr",
    path: trpcsNuzv5KgYBMeta?.path ?? "/trpc",
    meta: trpcsNuzv5KgYBMeta || {},
    alias: trpcsNuzv5KgYBMeta?.alias || [],
    redirect: trpcsNuzv5KgYBMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/trpc.vue").then(m => m.default || m)
  },
  {
    name: trpcsNuzv5KgYBMeta?.name ?? "trpc___en",
    path: trpcsNuzv5KgYBMeta?.path ?? "/en/trpc",
    meta: trpcsNuzv5KgYBMeta || {},
    alias: trpcsNuzv5KgYBMeta?.alias || [],
    redirect: trpcsNuzv5KgYBMeta?.redirect || undefined,
    component: () => import("/root/films-gratis-fullstack/pages/trpc.vue").then(m => m.default || m)
  }
]